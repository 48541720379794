import 'dfp/lazy-load.js';
import 'dfp/dfp-manager.js';
// due to dynamic import paths
// eslint-disable-next-line import/no-unresolved
import 'dfp/dfp-mappings-ad-slots.js';

// This script should be blocked by AdBlock when it's enabled.
// That way the AdBlockEnabled value that it's setting would be undefined.
// It's used to catch Sentry errors, especially in IE11 & iOS Safari.
window.AdBlockDisabled = true;

if (window.console && window.console.warn) {
  window.console.warn(`AdBlock: ${!window.AdBlockDisabled}`);
}
