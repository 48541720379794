/* global $, gptadslots, googletag */

/**
  * 1) Use a more abstract renderItem method name instead of renderAdSlot and
  * Lytics instead of Lytics to prevent blocking it by AdBlock.
  */
document.addEventListener('DOMContentLoaded', () => {
  const lazyAds = {
    sitesWithAmazonScript: [
      'www.police1.com',
      // 'www.corrections1.com',
      // 'www.firerescue1.com',
      // 'www.ems1.com',
      // 'www.military1.com',
      'police1.webstage.lexipol.com',
      // 'corrections1.webstage.lexipol.com',
      // 'firerescue1.webstage.lexipol.com',
      // 'ems1.webstage.lexipol.com',
      // 'military1.webstage.lexipol.com',
    ],
    ready: (window.lazyAds && window.lazyAds.ready) ?
      window.lazyAds.ready : false,
    renderQueue: (window.lazyAds && window.lazyAds.renderQueue) ?
      window.lazyAds.renderQueue : [],
    renderItem(adSlotNumber) { /* 1 */
      if (!this.sitesWithAmazonScript.includes(window.location.host)) {
        // Check GPT APi ready and PubAdsService fully loaded flags
        if (googletag && googletag.apiReady && googletag.pubadsReady) {
          googletag.pubads().refresh([gptadslots[adSlotNumber]]);
        } else {
          googletag.cmd.push(() => {
            googletag.pubads().refresh([gptadslots[adSlotNumber]]);
          });
        }
      } else if (window.pubwise) {
        // Check GPT APi ready and PubAdsService fully loaded flags
        // and use Pubwise method render ad
        if (googletag && googletag.apiReady && googletag.pubadsReady) {
          if (window.performPubWiseLoad) {
            window.performPubWiseLoad([gptadslots[adSlotNumber]], true);
          }
        } else {
          googletag.cmd.push(() => {
            if (window.performPubWiseLoad) {
              window.performPubWiseLoad([gptadslots[adSlotNumber]], true);
            }
          });
        }
      } else if (googletag && googletag.apiReady && googletag.pubadsReady) {
        // Check GPT APi ready and PubAdsService fully loaded flags
        googletag.pubads().refresh([gptadslots[adSlotNumber]]);
      } else {
        googletag.cmd.push(() => {
          googletag.pubads().refresh([gptadslots[adSlotNumber]]);
        });
      }
    },

    renderItemUsingLytcs(adSlotNumber) { /* 1 */
      const _this = this;

      // If Lytics hasn't had formed its targets yet
      if (!(window.lio && window.lio.hasEntity) &&
        // And Lytics callback exists
        (window.liosetup &&
          typeof window.liosetup.addEntityLoadedCallback === 'function')) {
        // Use Lytics callback to render banners after
        // the targets would be formed
        window.liosetup.addEntityLoadedCallback(
          () => _this.renderItem(adSlotNumber),
        );
      // Simply render banner items
      } else {
        this.renderItem(adSlotNumber);
      }
    },

    initEventHandlerToShowAdOnScroll(object) {
      let processedShowAdRequest = false;
      let stopRequestingFrames = false;
      const adSlotNumber = object.attr('data-adslot-number');
      const deltaY = 50;
      let scrollY = 0;

      const renderAdOnScroll = (lastScrollY) => {
        if (!processedShowAdRequest &&
          lastScrollY + $(window).height() - deltaY >= object.offset().top
        ) {
          processedShowAdRequest = true;
          window.lazyAds.renderItemUsingLytcs(adSlotNumber);
        }
      };

      const showAdOnScroll = () => {
        scrollY = window.scrollY;
        if (!(stopRequestingFrames || processedShowAdRequest)) {
          window.requestAnimationFrame(() => {
            renderAdOnScroll(scrollY);
            stopRequestingFrames = false;
          });
          stopRequestingFrames = true;
        }
      };

      $(document).ready(showAdOnScroll);
      $(window).on('scroll', showAdOnScroll);

      return true;
    },
  };

  lazyAds.ready = true;
  window.lazyAds = lazyAds;

  // render deferred items added while this script wasn't loaded
  if (window.lazyAds.renderQueue && window.lazyAds.renderQueue.length) {
    window.lazyAds.renderQueue.forEach((item) => {
      window.lazyAds.renderItemUsingLytcs(item);
    });
  }
});
