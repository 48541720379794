/* global googletag, dfpSettings, mediaUtils */
/**
 * DFP js module. For now it's only for setting targets.
 * 1) Read Targets from global variable dfpSettings that sets in html code
 * 2) Parameter for disabling initial banner load and use Lazy Load instead
 */

function isEmpty(obj) {
  let prop;
  for (prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return true;
}

function dfpTargetKeyValueFormat(str) {
  const regexp = /[\"'`’=!+#*~;^\(\)<>\[\],.& ]/gi;
  return str.replace(regexp, '_');
}

// eslint-disable-next-line import/prefer-default-export
export const dfpManager = {
  _isInitialLoad: true, /* 2 */
  _targets: '',

  _readParams() {
    if (typeof dfpSettings === 'undefined') return;

    if (typeof dfpSettings.isInitialLoad !== 'undefined') {
      this._isInitialLoad = dfpSettings.isInitialLoad;
    }

    if ((typeof dfpSettings.targets !== 'undefined') &&
      (dfpSettings.targets > '')) {
      try {
        this._targets = dfpSettings.targets; /* 1 */
      } catch (e) {} // eslint-disable-line no-empty
    }
  },

  setTargets(dfpGoogleTag) {
    const googletagInstance = googletag || dfpGoogleTag || {};

    this._readParams();

    if (!isEmpty(this._targets)) {
      if (this._targets.Topic) {
        googletagInstance.pubads().setTargeting('topic',
          dfpTargetKeyValueFormat(this._targets.Topic));
      }
      if (this._targets.Url) {
        googletagInstance.pubads().setTargeting('URL', this._targets.Url);
      }
      if (this._targets.Category) {
        googletagInstance.pubads().setTargeting('category',
          this._targets.Category);
      }
      if (this._targets.SubCategory) {
        googletagInstance.pubads().setTargeting('subcat',
          dfpTargetKeyValueFormat(this._targets.SubCategory));
      }
      if (parseInt(this._targets.ArticleId, 10) > 0) {
        googletagInstance.pubads().setTargeting('article',
          `${this._targets.ArticleId}`);
      }
      if (this._targets.ArticleUid) {
        googletagInstance.pubads().setTargeting('article_uid',
          `${this._targets.ArticleUid}`);
      }
      if (this._targets.Campaign) {
        googletagInstance.pubads().setTargeting('campaign',
          dfpTargetKeyValueFormat(this._targets.Campaign));
      }
      if (this._targets.Source) {
        googletagInstance.pubads().setTargeting('source',
          dfpTargetKeyValueFormat(this._targets.Source));
      }
      if (this._targets.Author) {
        googletagInstance.pubads().setTargeting('author',
          dfpTargetKeyValueFormat(this._targets.Author));
      }
      if (this._targets.Sitename) {
        googletagInstance.pubads().setTargeting('Sitename',
          dfpTargetKeyValueFormat(this._targets.Sitename));
      }
      if (this._targets.PCSponsored) {
        googletagInstance.pubads().setTargeting('PCSponsored',
          this._targets.PCSponsored.map(dfpTargetKeyValueFormat));
      }
      if (this._targets.TopicSponsored) {
        googletagInstance.pubads().setTargeting('TopicSponsored',
          this._targets.TopicSponsored.map(dfpTargetKeyValueFormat));
      }

      googletagInstance.pubads().setTargeting('staging',
        `${this._targets.IsStaging ||
        (mediaUtils.getQueryStringValue('staging') ===
        '1')}`);
      googletag.pubads().setTargeting('NORESP',
        this._targets.IsResponsive ? 'false' : 'true');
      googletag.pubads().setTargeting('IsSponsored',
        this._targets.IsSponsored ? 'true' : 'false');
      googletag.pubads().setTargeting('isUITestingMode',
        `${mediaUtils.getQueryStringValue('dfp_ui_testing_mode') === '1'}`);
    }
  },

  enable() {
    // googletag.pubads().collapseEmptyDivs(false);
    googletag.pubads().enableAsyncRendering();

    if (!this._isInitialLoad) {
      googletag.pubads().disableInitialLoad();
    }
    googletag.enableServices();
  },
};
